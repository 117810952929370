// import store
import store from "../store/index";

// POST api/v1/adgroup/settype
const adGroupSetType = async (adGroupId: string, adGroupTypeId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/settype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId,
                adGroupTypeId: adGroupTypeId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateAdGroupTypeCache", {
                adGroupType: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAdGroupTypeCache", {
                adGroupType: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAdGroupTypeCache", {
            adGroupType: null
        });
    }
};
// POST api/v1/adgroup/quicksettype
const adGroupQuickSetType = async (adGroupId: string, adGroupTypeName: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/quicksettype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId,
                adGroupTypeName: adGroupTypeName
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateAdGroupTypeCache", {
                adGroupType: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAdGroupTypeCache", {
                adGroupType: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAdGroupTypeCache", {
            adGroupType: null
        });
    }
};
// POST api/v1/adgroup/unsettype
const adGroupUnsetType = async (adGroupId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/unsettype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return 
            return true;
        }
        else {
            // return 
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/adgroup/read/:adGroupId
const adGroupRead = async (adGroupId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/read/${adGroupId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            store.commit("updateAdGroupCache", {
                adGroupType: jsonResponse.data
            });
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAdGroupTypeCache", {
                adGroupType: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAdGroupTypeCache", {
            adGroupType: null
        });
    }
};
// GET api/v1/adgroup/readall
const adGroupReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateAdGroupCache", {
                adGroupList: jsonResponse.data
            });
            // return
            return jsonResponse.data
        }
        else {
            // MANIPULATE STORE
            store.commit("updateAdGroupCache", {
                adGroupList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateAdGroupCache", {
            adGroupList: []
        });
        // return
        return [];
    }
};
// POST api/v1/adgroup/getnegativekeywords
const adGroupGetNegativeKeywordsCount = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/adgroup/getnegativekeywords`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return 
        return false;
    }
};

// export
export {
    adGroupRead,
    adGroupReadAll,
    adGroupSetType,
    adGroupUnsetType,
    adGroupQuickSetType,
    adGroupGetNegativeKeywordsCount
};