// import store
import store from "../store/index";

// GET api/v1/campaign/read/:campaignId
const campaignRead = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/read/${campaignId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaign: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaign: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaign: null
        });
    }
};
// GET api/v1/campaign/readall
const campaignReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};
// POST api/v1/campaign/settype
const campaignSetType = async (campaignId: string, campaignTypeId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/settype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId,
                campaignTypeId: campaignTypeId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};
// POST api/v1/campaign/unsettype
const campaignUnsetType = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/unsettype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};

// POST api/v1/campaign/getnegativekeywords
const campaignGetNegativeKeywordsCount = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/getnegativekeywords`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return 
        return false;
    }
};

// export
export {
    campaignRead,
    campaignReadAll,
    campaignSetType,
    campaignUnsetType,
    campaignGetNegativeKeywordsCount
};